import React from "react";
import {
  CreateButton,
  TextField,
  TopToolbar,
  ReferenceField,
  FunctionField,
  DateField,
  useGetIdentity,
} from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import PageTitle from "../../components/navigation/PageTitle";
import { ListActions } from "../../components/react-admin/ListActions";

const PaymentMethodElement = ({ paymentMethod }: any) => {
  const visa = require("../../assets/visa.png") as string;
  const mastercard = require("../../assets/mastercard.png") as string;
  const amex = require("../../assets/amex.png") as string;
  const sepa = require("../../assets/sepa.png") as string;
  const paymentMethodToImage: any = {
    visa: visa,
    mastercard: mastercard,
    amex: amex,
    sepa: sepa,
  };
  const type = paymentMethod.type === "card" ? paymentMethod.card.brand : "sepa";
  return ["visa", "amex", "sepa", "mastercard"].includes(type) ? (
    <img src={paymentMethodToImage[type]} alt={type} width="40" height="25" />
  ) : (
    <div />
  );
};

const PaymentMethodField = ({ props }: any) => {
  return (
    <FunctionField
      render={(record: any) => {
        return <PaymentMethodElement paymentMethod={record} />;
      }}
    />
  );
};

const PaymentMethodsList = ({ ...props }) => {
  const { isLoading, identity } = useGetIdentity();
  return isLoading ? null : (
    <>
      {/* {!props.hideTitle && <PageTitle text="Moyens de paiement" backButton={false} />} */}
      <List
        perPage={10}
        {...props}
        actions={<></>}
        filter={{ group_id: props.company || identity?.company }}
        exporter={false}
        empty={false}
        sx={{ width: 1 }}
        sort={{ field: "created", order: "DESC" }}
      >
        <CustomDatagrid bulkActionButtons={false}>
          <FunctionField
            label="Créé le"
            render={(record: any) => {
              return (
                <DateField
                  source="createdDate"
                  record={{ id: record.id, createdDate: record.created * 1000 }}
                  showTime
                />
              );
            }}
          />
          <FunctionField
            label="Nom complet"
            render={(record: any) => {
              return (
                <div>
                  {record.billing_details?.name.replaceAll("undefined", "").replaceAll("null", "") || "Non renseigné"}
                </div>
              );
            }}
          />
          <PaymentMethodField label="Type" />
          <FunctionField
            label="Numéro"
            render={(record: any) => {
              const identificator =
                record.type === "card"
                  ? "**** **** **** " + record.card.last4
                  : `${record.sepa_debit.country}**${record.sepa_debit.bank_code}${record.sepa_debit.branch_code}*********${record.sepa_debit.last4}`;
              return <div>{identificator}</div>;
            }}
          />
          <FunctionField
            label="Expiration"
            render={(record: any) => {
              return record.type === "card" ? (
                <div>
                  {record.card.exp_month}/{record.card.exp_year}
                </div>
              ) : (
                <div />
              );
            }}
          />
          <TextField label="Nombre d'abonnements associés" source="subscriptionsNumber" />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default PaymentMethodsList;
