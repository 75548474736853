import ContactsList from "./ContactsList";
import ContactsCreate from "./ContactsCreate";
import ContactsEdit from "./ContactsEdit";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: ContactsList,
  create: ContactsCreate,
  edit: ContactsEdit,
};
