import React from "react";
import { CreateButton, DateField, Filter, TextField, FunctionField } from "react-admin";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import { List } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { ListActions } from "../../components/react-admin/ListActions";

const DiscountsList = ({ ...props }) => {
  return (
    <>
      <PageTitle
        text="Remises"
        createButton={<CreateButton label="Nouvelle remise" resource="discounts" />}
        type="list"
        breadcrumbListLabel="Liste des remises"
      />
      <List
        perPage={10}
        {...props}
        filters={
          <Filter {...props}>
            <FormInput placeholder="Nom remise" name="name" type="search" alwaysOn />
          </Filter>
        }
        actions={<ListActions />}
        exporter={false}
        sort={{ field: "name", order: "ASC" }}
      >
        <CustomDatagrid>
          <TextField source="name" label="Nom" />
          <DateField source="startDate" label="Débute le" />
          <FunctionField
            label="Termine le"
            render={(record: any) => {
              return <span>{record.expire && record.endDate}</span>;
            }}
          />
          <TextField source="applicationType" label="Type de remise" />
          <TextField source="type" label="Type" />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default DiscountsList;
