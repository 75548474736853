import TemplatesList from './TemplatesList';
import TemplatesCreate from './TemplatesCreate';
import TemplatesEdit from './TemplatesEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: TemplatesList,
  create: TemplatesCreate,
  edit: TemplatesEdit
};
