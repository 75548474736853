export const isEmailValid = (email: string) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);

export const sanitizePhoneNumber = (phoneNumber: string) => {
  let updatedPhoneNumber = phoneNumber.replace(/\s/g, "");
  if (updatedPhoneNumber.length === 10 && updatedPhoneNumber.charAt(0) === "0") {
    updatedPhoneNumber = "+33" + updatedPhoneNumber.substring(1);
  }
  if (updatedPhoneNumber.length === 11 && updatedPhoneNumber.startsWith("33")) {
    updatedPhoneNumber = "+33" + updatedPhoneNumber.substring(2);
  }
  return updatedPhoneNumber;
};
