import { Grid } from "@mui/material";
import React from "react";
import { Create, useNotify, useRedirect } from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { BADWORDS_TYPES } from "../../services/common-utils/constants";

const BadWordsCreate = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useRedirect();

  // Success override
  const onSuccess = async (data: any) => {
    notify(`Mot interdit ajouté avec succès`, { type: "success" });
    redirect("list", "badWords", data.id, data);
  };

  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <PageTitle
        text={"Ajout d'un nouveau mot interdit"}
        type="create"
        resource="badWords"
        breadcrumbListLabel={"Liste des mots interdits"}
        breadcrumbListUrl="/admin/digisoft/5"
        breadcrumbCreateLabel={"Nouveau mot interdit"}
      />
      <SimpleForm>
        <Grid container direction={"column"}>
          <FormInput required source="word" label="Mot" />
          <FormInput
            type="select"
            required
            defaultValue="both"
            source="type"
            label="Champ d'application"
            values={BADWORDS_TYPES}
          />
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default BadWordsCreate;
