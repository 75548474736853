import PaymentMethodsList from './PaymentMethodsList';
import PaymentMethodsCreate from './PaymentMethodsCreate';
import PaymentMethodsEdit from './PaymentMethodsEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: PaymentMethodsList,
  create: PaymentMethodsCreate,
  edit: PaymentMethodsEdit
};
