import React, { useContext, useEffect } from "react";
import "./App.css";
import { Admin, CustomRoutes, combineDataProviders, localStorageStore } from "react-admin";
import { Route } from "react-router-dom";
import { Resource } from "@react-admin/ra-rbac";

import GroupIcon from "@mui/icons-material/Group";
import Layout from "./components/layout/Layout";
import { fbDataProvider, fbAuthProvider } from "./services/firebase/firebase.providers";
import apiSmsMeetingProvider from "./services/firebase/api.dataprovider";
import LoginPage from "./pages/login/LoginPage";
import SignUpPage from "./pages/sign-up/SignUpPage";
import ForgottenPasswordPage from "./pages/forgotten-password/ForgottenPasswordPage";
import { Theme } from "./components/Theme";

// Pages
import CollaboratorsPages from "./pages/collaborators";
import CompaniesPages from "./pages/companies";
import UsersPages from "./pages/users";
import OrdersPages from "./pages/orders";
import ProductsPages from "./pages/products";
import DiscountsPages from "./pages/discounts";
import SubscriptionsPages from "./pages/subscriptions";
import LicensesPages from "./pages/licenses";
import TemplatesPages from "./pages/templates";
import ContactsPages from "./pages/contacts";
import ContactListsPages from "./pages/contactLists";
import ContactsOrContactListsPage from "./pages/contactsOrContactLists/ContactsOrContactLists";
import NotificationsPage from "./pages/notifications/NotificationsPage";
import SmsPages from "./pages/sms";
import ProvidersPages from "./pages/providers";
import BadWordsPages from "./pages/badWords";
import PaymentMethodsPages from "./pages/paymentMethods";
import EmailsPages from "./pages/emails";
import GroupsPages from "./pages/groups";
import OpportunitiesPages from "./pages/opportunities";
import DownloadsPages from "./pages/downloads";
import ShopPage from "./pages/shop/ShopPage";
import MyOfferPage from "./pages/my-offer/MyOfferPage";
import ProductPage from "./pages/shop/product/ProductPage";
import CheckoutPage from "./pages/checkout/CheckoutPage";
import CreditsPage from "./pages/credits/CreditsPage";
import DownloadPage from "./pages/download/DownloadPage";
import DigisoftEdit from "./pages/admin/DigisoftEdit";
import polyglotI18nProvider from "ra-i18n-polyglot";
import fr from "ra-language-french";
import { fr as csvFr } from "react-admin-import-csv/lib/i18n";
import { raRelationshipsLanguageFrench } from "@react-admin/ra-relationships";
import DashboardPage from "./pages/dashboard/DashboardPage";
import { useMediaQuery } from "@mui/material";
import { z } from "zod";

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.received === "null") {
      return { message: "Merci de compléter ce champ" };
    }
  }
  if (issue.code === z.ZodIssueCode.custom) {
    return { message: `less-than-${(issue.params || {}).minimum}` };
  }
  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);

csvFr.csv.buttonMain.tooltip = "Si excel faire 'enregistrer sous' et sélectionner comme type de fichier 'CSV UTF8'";
fr.ra.auth.user_menu = "Paramètres";
fr.ra.action.delete = "Supprimer définitivement";
export const i18nProvider = polyglotI18nProvider(
  (locale) => ({ ...fr, ...csvFr, ...raRelationshipsLanguageFrench }),
  "fr", // default locale
  [{ locale: "fr", name: "Français", allowMissing: true }]
);

const dataProvider = combineDataProviders((resource: string) => {
  if (
    [
      "licenses",
      "templates",
      "contacts",
      "contactLists",
      "transfers",
      "sms",
      "providers",
      "badWords",
      "paymentMethods",
      "emails",
    ].includes(resource)
  ) {
    return apiSmsMeetingProvider;
  } else {
    return fbDataProvider;
  }
});

const STORE_VERSION = "3";

function App() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const lightTheme = Theme("light", isMobile);
  const darkTheme = Theme("dark", isMobile);

  return (
    <Admin
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={fbAuthProvider}
      loginPage={LoginPage}
      layout={Layout}
      theme={lightTheme}
      darkTheme={darkTheme}
      dashboard={DashboardPage}
      requireAuth
      store={localStorageStore(STORE_VERSION)}
    >
      <Resource name="admin" icon={GroupIcon} edit={DigisoftEdit} />
      <Resource name="collaborators" icon={GroupIcon} {...CollaboratorsPages} />
      <Resource name="companies" icon={GroupIcon} {...CompaniesPages} />
      <Resource name="users" icon={GroupIcon} {...UsersPages} />
      <Resource name="orders" icon={GroupIcon} {...OrdersPages} />
      <Resource name="products" icon={GroupIcon} {...ProductsPages} />
      <Resource name="discounts" icon={GroupIcon} {...DiscountsPages} />
      <Resource name="subscriptions" icon={GroupIcon} {...SubscriptionsPages} />
      <Resource name="licenses" icon={GroupIcon} {...LicensesPages} />
      <Resource name="templates" icon={GroupIcon} {...TemplatesPages} />
      <Resource name="contacts" icon={GroupIcon} {...ContactsPages} />
      <Resource name="contactLists" icon={GroupIcon} {...ContactListsPages} />
      <Resource name="sms" icon={GroupIcon} {...SmsPages} />
      <Resource name="sms-statistics" icon={GroupIcon} {...SmsPages} />
      <Resource name="providers" icon={GroupIcon} {...ProvidersPages} />
      <Resource name="badWords" icon={GroupIcon} {...BadWordsPages} />
      <Resource name="paymentMethods" icon={GroupIcon} {...PaymentMethodsPages} />
      <Resource name="emails" icon={GroupIcon} {...EmailsPages} />
      <Resource name="groups" icon={GroupIcon} {...GroupsPages} />
      <Resource name="downloads" icon={GroupIcon} {...DownloadsPages} />
      <Resource name="opportunities" icon={GroupIcon} {...OpportunitiesPages} />

      {/* Custom routes */}
      <CustomRoutes noLayout>
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/forgotten-password" element={<ForgottenPasswordPage />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/myOffer" element={<MyOfferPage />} />
        <Route path="/myOffer/creditSubscription" element={<ProductPage productType="creditSubscription" />} />
        <Route path="/myOffer/licenceSubscription" element={<ProductPage productType="licenceSubscription" />} />
        <Route path="/myOffer/credits" element={<ProductPage productType="credits" />} />
        <Route path="/download" element={<DownloadPage />} />
        <Route path="/credits" element={<CreditsPage />} />
        <Route path="/contactsOrContactLists" element={<ContactsOrContactListsPage />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/checkout" element={<CheckoutPage />} />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
