import axios from 'axios';

let url = process.env.REACT_APP_CLOUD_FUNCTION_API_URL;
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  url = 'http://127.0.0.1:5001/'+process.env.REACT_APP_FIREBASE_PROJECT_ID+'/europe-west1/digisoftApp/api'
}

export const apiUrl = url;

export default axios.create({
  baseURL: url
})