import GroupsList from './GroupsList';
import GroupsEdit from './GroupsEdit';
import GroupsCreate from './GroupsCreate';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: GroupsList,
  create: GroupsCreate,
  edit: GroupsEdit
};
