import OpportunitiesList from './OpportunitiesList';
import OpportunitiesCreate from './OpportunitiesCreate';
import OpportunitiesEdit from './OpportunitiesEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: OpportunitiesList,
  create: OpportunitiesCreate,
  edit: OpportunitiesEdit
};
