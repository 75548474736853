import React from "react";
import { CreateButton, DateField, Filter, TextField } from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import { ListActions } from "../../components/react-admin/ListActions";
import PageTitle from "../../components/navigation/PageTitle";

const ProvidersList = ({ ...props }) => {
  return (
    <>
      <PageTitle
        text="Providers"
        createButton={<CreateButton label="Nouveau provider" resource="providers" />}
        type="list"
        breadcrumbListLabel="Liste des providers"
      />
      <List perPage={10} {...props} actions={<ListActions />} exporter={false} empty={false}>
        <CustomDatagrid bulkActionButtons={false}>
          <TextField label="Identifiant" source="id" sortable={false} />
          <TextField label="Nom" source="name" sortable={false} />
          <TextField label="Url d'entrée" source="entry_point" sortable={false} />
          <DateField label="Mis à jour le" source="updatedAt" showTime sortable={false} />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default ProvidersList;
