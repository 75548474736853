import DownloadsList from './DownloadsList';
import DownloadsCreate from './DownloadsCreate';
import DownloadsEdit from './DownloadsEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: DownloadsList,
  create: DownloadsCreate,
  edit: DownloadsEdit
};
