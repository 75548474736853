import * as firebase from "@firebase/auth-types";
import { collection, doc, updateDoc, getDoc, getDocs, query, where, arrayUnion } from "firebase/firestore";
import { AuthProvider } from "react-admin";
import { FirebaseDataProvider } from "react-admin-firebase/dist/src";
import { fbAuth, fbConfig, fbFirestore, fbApp } from "./firebase.services";
import _ from "lodash";
import {
  signInWithEmailLink,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  browserSessionPersistence,
  setPersistence,
  browserLocalPersistence,
  getAuth,
  signInWithCustomToken,
} from "firebase/auth";
import api from "../apiWithAuth";
declare const window: any;

// Public routes with authentication bypass
export const PUBLIC_ROUTES = ["/sign-up", "/forgotten-password"];

const app = fbApp;

// const provider = FirebaseDataProvider(fbConfig, {logging: true, useFileNamesInStorage: true});
const provider = FirebaseDataProvider(fbConfig, { useFileNamesInStorage: true, app });

const customProvider = {
  ...provider,
  getOne: async (resource: string, params: any) => {
    const record: any = await provider.getOne(resource, params);
    if (resource === "subscriptions") {
      if (record.data.type === "Licence") {
        const licenses = await Promise.all(
          record.data.licenses.map(async (licenseId: string) => {
            const licenceRes = await api.get(`licenses/${licenseId}`);
            return licenceRes.data;
          })
        );
        record.data.licenses = licenses;
      }
    }
    return record;
  },
  getList: async (resource: string, params: any) => {
    let list: any = {};
    //sanitize boolean filters
    Object.keys(params?.filter).forEach((field) => {
      params.filter[field] = ["true", "false"].includes(params.filter[field])
        ? params.filter[field] === "true"
        : params.filter[field];
    });
    const filters = params?.filter;
    const pagination = { ...params?.pagination };
    //hack to search on multiple fields, not possible directly on firebase so search done after
    let multiFilterKey = Object.keys(filters).find((filter: string) => filter.includes("multi_"));
    let multiFilterValue;
    let multiFilterFields;
    if (multiFilterKey) {
      //need to have all result to do filtering after, not ideal with pagination but better than nothing
      params.pagination.perPage = 1000;
      multiFilterFields = multiFilterKey?.split("_");
      multiFilterFields.shift();
      multiFilterValue = filters[multiFilterKey];
      delete filters[multiFilterKey];
    }
    // if (resource === "payments") {
    //   params.pagination.perPage = 1000;
    // }
    list = await provider.getList(resource, params);
    if (multiFilterValue) {
      list.data = list.data.filter((record: any) => {
        let searchableString = "";
        multiFilterFields.forEach((field) => {
          searchableString += record[field] + " ";
        });
        searchableString = normalizeString(searchableString);
        return searchableString.includes(normalizeString(multiFilterValue));
      });
    }
    if (resource === "subscriptions") {
      await Promise.all(
        list.data.map(async (subscription: any) => {
          if (subscription.discount) {
            const discount: any = await provider.getOne("discounts", { id: subscription.discount });
            subscription.discount = discount.data;
          }
        })
      );
    }
    //only on companies list to avoid calling api each time there is an autocomplete on companies
    if (resource === "companies" && window.location.pathname === "/companies") {
      const groupIds = list.data.map((company: any) => {
        return company.id;
      });
      try {
        const { data: companiesCredits } = await api.get("/utils/getCredits/" + groupIds.join(","));
        list.data.forEach((company: any) => {
          company.credits = companiesCredits?.find(({ uuid }) => uuid === company.id)?.credits || 0;
        });
      } catch (err) {
        console.log("error getting companies credits", err);
      }
    }
    // if (resource === "payments" && window.location.pathname.includes("myOffer")) {
    //   console.log("data after", list.data);
    //   const paymentsByOrder = _(list.data || [])
    //     .groupBy((payment) => payment.stripe_id)
    //     .map((value, key) => ({ ...value[0] }))
    //     .value();
    //   console.log("pagination", pagination);
    //   list.data = paginate(paymentsByOrder, pagination.perPage, pagination.page);
    // }
    return list;
  },
};
export const fbDataProvider = customProvider;

// Identity
const getIdentity = async () => {
  if (fbAuth.currentUser) {
    const collaborator = await getDoc(doc(fbFirestore, "collaborators", fbAuth.currentUser?.email as string));
    if (collaborator.exists()) return { ...collaborator.data(), status: "Collaborateur" };
    const q = query(collection(fbFirestore, "users"), where("email", "==", fbAuth.currentUser?.email));
    const querySnapshot = await getDocs(q);
    const user = querySnapshot.docs[0].data();
    user.id = querySnapshot.docs[0].id;
    const company = await getDoc(doc(fbFirestore, "companies", user?.company as string));
    let parentCompanyData = {} as any;
    if (company.data()?.status === "Indirect") {
      const parentCompanyDoc = await getDoc(doc(fbFirestore, "companies", company.data()?.parentCompany as string));
      parentCompanyData = parentCompanyDoc.data();
    }
    return {
      ...user,
      status: company.data()?.status,
      type: company.data()?.type,
      freeTry: {
        ...company.data()?.freeTry,
        daysTillFreeTryEnd: company.data()?.freeTry.expirationDate
          ? Math.floor(
              (new Date(company.data()?.freeTry.expirationDate).valueOf() -
                new Date(new Date().toISOString().split("T")[0]).valueOf()) /
                86400000
            )
          : 0,
      },
      firstStepsCompleted: company.data()?.firstStepsCompleted || false,
      parentCompany: company.data()?.parentCompany,
      parentCompanyData,
      companyAddress: company.data()?.address,
      companyCountry: company.data()?.country,
      companyName: company.data()?.company,
      billingDetails: company.data()?.billingDetails,
    };
  } else {
    return Promise.reject();
  }
};

// React-Admin Auth Provider for Firebase
export const fbAuthProvider: AuthProvider = {
  /**
   * Login: send username and password to the auth server and get back credentials
   * @param {*} param0 - Credentials
   * @returns Firebase signin promise
   */
  login: async (credentials, redirectTo = "") => {
    // if different user delete cart
    const auth = fbAuth;
    if (credentials?.token) {
      await signInWithCustomToken(auth, credentials.token);
    } else {
      if (credentials.username !== localStorage.getItem("userEmail")) {
        localStorage.removeItem("cart");
      }
      localStorage.removeItem("discount");
      localStorage.removeItem("checkoutStep");
      localStorage.removeItem("clientSecret");
      localStorage.removeItem("paymentIntentId");
      localStorage.removeItem("orderReference");
      localStorage.removeItem("prevPaymenIntentTotals");
      if (localStorage.getItem("userEmail")) {
        localStorage.setItem("toReload", "true");
      } else {
        localStorage.removeItem("toReload");
      }
      localStorage.setItem("userEmail", credentials.username);
      await setPersistence(auth, credentials.rememberMe ? browserSessionPersistence : browserLocalPersistence);
      if (!credentials.password) {
        await signInWithEmailLink(auth, credentials.username, window.location.href);
      } else {
        await signInWithEmailAndPassword(auth, credentials.username, credentials.password);
      }
    }
  },

  /**
   * Check Auth: when the user navigates, make sure that their credentials are still valid
   * @returns Firebase checks promise
   */
  checkAuth: async () => {
    return new Promise<void>((resolve, reject) => {
      // Bypass public routes
      if (PUBLIC_ROUTES.includes(window.location.pathname)) {
        return resolve();
      }

      // Check user auth
      const auth: any = fbAuth;
      if (auth.currentUser) return resolve(auth.currentUser);
      const unsub = onAuthStateChanged(auth, (user: any) => {
        if (user) {
          unsub();
          return resolve(user);
        } else {
          reject({ redirectTo: "/login", message: false });
        }
      });
    });
  },

  /**
   * Logout: remove local credentials and notify the auth server that the user logged out
   * @returns Firebase signout promise
   */
  logout: async () => {
    //window.BrevoConversations.setZIndex(-1);
    return fbAuth.signOut();
  },

  /**
   * Get Identity: Get the user's profile
   * @returns Firebase entity (TODO: Get form collection)
   */
  getIdentity: async (): Promise<any> => getIdentity(),

  // Unused provider functions
  checkError: async (error: any) => Promise.resolve(),

  // It is possible to reduce lines using some map functions on resource arrays and flatten it at the end
  // but this is less readable
  getPermissions: async (params: any): Promise<any> => {
    const identity: any = await getIdentity();
    if (identity.status === "Collaborateur") {
      return [
        { action: "*", resource: "*" },
        { type: "deny", action: "*", resource: "shop" },
        { type: "deny", action: "*", resource: "myOffer" },
        { type: "deny", action: "*", resource: "my-company" },
        { type: "deny", action: "*", resource: "download" },
        { type: "deny", action: "clone", resource: "subscriptions" },
        { type: "deny", action: "clone", resource: "companies" },
        { type: "deny", action: "clone", resource: "licenses" },
        { type: "deny", action: ["delete", "clone", "export"], resource: "products" },
        { type: "deny", action: ["delete", "clone"], resource: "admin" },
        { type: "deny", action: ["export", "clone"], resource: "admin" },
      ];
    }
    if (identity.status === "Revendeur") {
      return [
        // global
        { type: "deny", action: "clone", resource: "users" },
        { type: "deny", action: "clone", resource: "orders" },
        { type: "deny", action: "clone", resource: "paymentMethods" },
        { type: "deny", action: "*", resource: "emails" },
        { type: "deny", action: "clone", resource: "emails" },
        { type: "deny", action: "clone", resource: "subscriptions" },
        { type: "deny", action: "clone", resource: "companies" },
        { type: "deny", action: "clone", resource: "licenses" },
        { type: "deny", action: "clone", resource: "contacts" },
        { type: "deny", action: "clone", resource: "contactLists" },
        { type: "deny", action: "clone", resource: "transfers" },
        { type: "deny", action: "clone", resource: "groups" },
        { type: "deny", action: "clone", resource: "sms" },
        { type: "deny", action: "clone", resource: "sms-statistics" },

        // custom routes
        { action: "*", resource: "shop" },
        { action: "*", resource: "myOffer" },
        { action: "*", resource: "download" },
        { action: "*", resource: "notifications" },
        { action: "*", resource: "credits" },
        { action: "*", resource: "contactsOrContactLists" },

        // users
        { action: "*", resource: "users" },
        { action: "*", resource: "users.*" },

        // orders
        { action: "*", resource: "orders" },
        { action: "*", resource: "orders.*" },
        { type: "deny", action: ["delete"], resource: "orders" },

        // paymentMethods
        { action: "*", resource: "paymentMethods" },
        { action: "*", resource: "paymentMethods.*" },

        // subscriptions
        { action: "*", resource: "subscriptions" },
        { action: "*", resource: "subscriptions.*" },
        { type: "deny", action: ["delete"], resource: "subscriptions" },
        { type: "deny", action: ["read", "write"], resource: "subscriptions.cancelled" },

        // companies
        { action: "*", resource: "companies" },
        { action: "*", resource: "companies.*" },
        { type: "deny", action: ["read", "write"], resource: "companies.status" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.account" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.users", record: { id: identity.company } },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.groups", record: { id: identity.company } },
        {
          type: "deny",
          action: ["read", "write"],
          resource: "companies.tab.subscriptions",
          record: { id: identity.company },
        },
        {
          type: "deny",
          action: ["read", "write"],
          resource: "companies.tab.licenses",
          record: { id: identity.company },
        },
        {
          type: "deny",
          action: ["read", "write"],
          resource: "companies.tab.templates",
          record: { id: identity.company },
        },
        {
          type: "deny",
          action: ["read", "write"],
          resource: "companies.tab.contactsOrContactLists",
          record: { id: identity.company },
        },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.sms", record: { id: identity.company } },
        {
          type: "deny",
          action: ["read", "write"],
          resource: "companies.tab.sms-statistics",
          record: { id: identity.company },
        },

        // licences
        { action: "*", resource: "licenses" },
        { action: "*", resource: "licenses.*" },
        { type: "deny", action: ["delete"], resource: "licenses" },

        // templates
        { action: "*", resource: "templates" },
        { action: "*", resource: "templates.*" },

        // contacts
        { action: "*", resource: "contacts" },
        { action: "*", resource: "contacts.*" },

        // contact lists
        { action: "*", resource: "contactLists" },
        { action: "*", resource: "contactLists.*" },

        // transfers
        { action: "*", resource: "transfers" },
        { action: "*", resource: "transfers.*" },

        // groups
        { action: "*", resource: "groups" },
        { action: "*", resource: "groups.*" },

        // sms
        { action: "*", resource: "sms" },
        { action: "*", resource: "sms.*" },

        // sms-statistics
        { action: "*", resource: "sms-statistics" },
        { action: "*", resource: "sms-statistics.*" },
        //{ type: 'deny', action: ["read", "write", "edit"], resource: 'subscriptions.company_id' },
        // { action: ["list", "show", 'read', 'edit'], resource: 'subscriptions', record: { "company_id": identity.company }},
        // { action: ["read", "edit"], resource: 'subscriptions.*', record: { "company_id": identity.company } },
        // { type: 'deny', action: ["delete"], resource: 'subscriptions', record: { "company_id": identity.company } },
        // { type: 'deny', action: ["read", "write"], resource: 'subscriptions.active' },
        //{ action: ["read", "edit"], resource: 'licenses.*', record: { "group_id": identity.company } },
      ];
    }
    if (["Client", "Indirect"].includes(identity.status)) {
      return [
        // global
        { type: "deny", action: "clone", resource: "users" },
        { type: "deny", action: "clone", resource: "orders" },
        { type: "deny", action: "clone", resource: "paymentMethods" },
        { type: "deny", action: "*", resource: "emails" },
        { type: "deny", action: "clone", resource: "subscriptions" },
        { type: "deny", action: "clone", resource: "companies" },
        { type: "deny", action: "clone", resource: "licenses" },
        { type: "deny", action: "clone", resource: "contacts" },
        { type: "deny", action: "clone", resource: "contactLists" },
        { type: "deny", action: "clone", resource: "transfers" },
        { type: "deny", action: "clone", resource: "groups" },
        { type: "deny", action: "clone", resource: "sms" },
        { type: "deny", action: "clone", resource: "sms-statistics" },

        // custom routes
        { action: "*", resource: "shop" },
        { action: "*", resource: "myOffer" },
        { action: "*", resource: "download" },
        { action: "*", resource: "credits" },
        { action: "*", resource: "notifications" },
        { action: "*", resource: "contactsOrContactLists" },

        // users
        { action: "*", resource: "users", record: { company: identity.company } },
        { action: "*", resource: "users.*", record: { company: identity.company } },
        { type: "deny", action: ["write"], resource: "users.company" },

        // orders
        { action: "*", resource: "orders" },
        { action: "*", resource: "orders.*" },
        { type: "deny", action: ["delete"], resource: "orders" },

        // paymentMethods
        { action: "*", resource: "paymentMethods" },
        { action: "*", resource: "paymentMethods.*" },

        //subscriptions
        { action: "*", resource: "subscriptions", record: { company_id: identity.company } },
        { action: "*", resource: "subscriptions.*", record: { company_id: identity.company } },
        { type: "deny", action: ["delete"], resource: "subscriptions" },
        { type: "deny", action: ["read", "write"], resource: "subscriptions.cancelled" },

        // companies
        { action: "*", resource: "companies", record: { id: identity.company } },
        { action: "*", resource: "companies.*", record: { id: identity.company } },
        { type: "deny", action: ["delete"], resource: "companies" },
        { type: "deny", action: ["read", "write"], resource: "companies.status" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.account" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.users" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.groups" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.subscriptions" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.licenses" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.templates" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.contactsOrContactLists" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.sms" },
        { type: "deny", action: ["read", "write"], resource: "companies.tab.sms-statistics" },

        // licences
        { action: "*", resource: "licenses", record: { group_id: identity.company } },
        { action: "*", resource: "licenses.*", record: { group_id: identity.company } },
        { type: "deny", action: ["delete"], resource: "licenses" },
        { type: "deny", action: ["read", "write"], resource: "licenses.group_id" },
        { type: "deny", action: ["read", "write"], resource: "licenses.disabled" },
        { type: "deny", action: ["read", "write"], resource: "licenses.isAdmin" },

        // templates
        { action: "*", resource: "templates", record: { group_id: identity.company } },
        { action: "*", resource: "templates.*", record: { group_id: identity.company } },
        { type: "deny", action: ["read", "write"], resource: "templates.group_id" },

        // contacts
        { action: "*", resource: "contacts", record: { group_id: identity.company } },
        { action: "*", resource: "contacts.*", record: { group_id: identity.company } },
        { type: "deny", action: ["read", "write"], resource: "contacts.group_id" },

        // contact lists
        { action: "*", resource: "contactLists", record: { group_id: identity.company } },
        { action: "*", resource: "contactLists.*", record: { group_id: identity.company } },
        { type: "deny", action: ["read", "write"], resource: "contactLists.group_id" },

        // transfers
        { action: "*", resource: "transfers" },
        { action: "*", resource: "transfers.*" },

        // groups
        { action: "*", resource: "groups", record: { group_id: identity.company } },
        { action: "*", resource: "groups.*", record: { group_id: identity.company } },

        // sms
        { action: "*", resource: "sms", record: { group_id: identity.company } },
        { action: "*", resource: "sms.*", record: { group_id: identity.company } },

        // sms-statistics
        { action: "*", resource: "sms-statistics", record: { group_id: identity.company } },
        { action: "*", resource: "sms-statistics.*", record: { group_id: identity.company } },
      ];
    }
    return [{ type: "deny", action: "*", resource: "*" }];
  },
};

const paginate = (array: string | unknown[], pageSize: number, pageNumber: number) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

export const normalizeString = (str: string): string =>
  str
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
