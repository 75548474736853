import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  Divider,
  InputAdornment,
  FormControlLabel,
  FormGroup,
  Checkbox,
  useTheme,
  Card,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import {
  useGetIdentity,
  useDataProvider,
  List,
  TextField,
  DateField,
  FunctionField,
  ReferenceField,
  Count,
  useListContext,
} from "react-admin";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import api from "../../services/apiWithAuth";
import FormInput from "../../components/form/FormInput";
import { useForm, FormProvider, useWatch } from "react-hook-form";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import PageTitle from "../../components/navigation/PageTitle";
import { query, where } from "firebase/firestore";
import CreateTransfer from "./CreateTransfer";
import ListChip from "../../components/fields/ListChip";

const CreditsList = ({ ...props }) => {
  const { isLoading, data: identity } = useGetIdentity();
  const theme = useTheme();
  return isLoading ? null : (
    <List
      {...props}
      perPage={10}
      sort={{ field: "createdAt", order: "DESC" }}
      hasCreate={false}
      exporter={false}
      actions={false}
      resource={"transfers"}
      empty={false}
      sx={{ width: "100%" }}
    >
      <CustomDatagrid bulkActionButtons={false} rowClick={false}>
        <DateField label="Date d'opération" source="createdAt" showTime={true} />
        {identity?.status === "Collaborateur" && (
          <FunctionField
            label="Source"
            render={({ fromOrder, fromSubscription }: any) => (
              <Typography fontSize="0.85rem">
                {fromOrder || fromSubscription ? "Commande / Abonnement" : "Interne"}
              </Typography>
            )}
          />
        )}
        {["Collaborateur", "Revendeur"].includes(identity?.status) && (
          <ReferenceField label="Organisation" source="group_id" reference="companies" link={false}>
            <TextField source="company" />
          </ReferenceField>
        )}
        <FunctionField
          label="Crédits"
          render={(record: any) => {
            const creditsColor = record.type === "add" ? "secondary" : "error";
            return (
              <ListChip
                label={
                  <>
                    {record.type === "add" ? "+" : "-"}
                    {record.amount}
                  </>
                }
                color={creditsColor}
              />
            );
          }}
        />
      </CustomDatagrid>
    </List>
  );
};

const CreditsPage = ({ company, ...props }: any) => {
  const [instanceKey, setInstanceKey] = useState(0);
  const theme = useTheme();

  return (
    <Box sx={{ width: 1 }}>
      <PageTitle
        text="Crédits SMS"
        type="list"
        breadcrumbListLabel="Crédits SMS"
        createButton={<CreateTransfer instanceKey={instanceKey} setInstanceKey={setInstanceKey} />}
      />

      <CreditsList key={instanceKey} {...props} />
    </Box>
  );
};

export default CreditsPage;
