import ContactListsList from './ContactListsList';
import ContactListsEdit from './ContactListsEdit';
import ContactListsCreate from './ContactListsCreate';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: ContactListsList,
  create: ContactListsCreate,
  edit: ContactListsEdit
};
