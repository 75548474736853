import ProductsList from './ProductsList';
import ProductsCreate from './ProductsCreate';
import ProductsEdit from './ProductsEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: ProductsList,
  create: ProductsCreate,
  edit: ProductsEdit
};
