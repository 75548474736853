import CollaboratorsList from './CollaboratorsList';
import CollaboratorsCreate from './CollaboratorsCreate';
import CollaboratorsEdit from './CollaboratorsEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: CollaboratorsList,
  create: CollaboratorsCreate,
  edit: CollaboratorsEdit
};
