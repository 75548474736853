import CompaniesList from './CompaniesList';
import CompaniesCreate from './CompaniesCreate';
import CompaniesEdit from './CompaniesEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: CompaniesList,
  create: CompaniesCreate,
  edit: CompaniesEdit
};
