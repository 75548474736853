import ProvidersList from './ProvidersList';
import ProvidersCreate from './ProvidersCreate';
import ProvidersEdit from './ProvidersEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: ProvidersList,
  create: ProvidersCreate,
  edit: ProvidersEdit
};
