import DiscountsList from "./DiscountsList";
import DiscountsCreate from "./DiscountsCreate";
import DiscountsEdit from "./DiscountsEdit";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: DiscountsList,
  create: DiscountsCreate,
  edit: DiscountsEdit,
};
