declare const window: any;
const loadChat = () => {
  const existingScript = document.getElementById('chat-script')
  if (!existingScript) {
    window.BrevoConversationsID = "6452b2df8c58db2be1650e57"
    window.BrevoConversations = window.BrevoConversations || function () {
      (window.BrevoConversations.q = window.BrevoConversations.q || []).push(arguments);
    }
    const script = document.createElement('script')
    script.async = true;
    script.src = 'https://conversations-widget.brevo.com/brevo-conversations.js'
    script.id = 'chat-script'
    document.body.appendChild(script)
  }
}
export default loadChat
