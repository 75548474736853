import React from "react";
import { TextField, DateField, useGetIdentity, FunctionField } from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import ListChip from "../../components/fields/ListChip";

export const emailEvents: any = {
  delivered: {
    color: "info",
    text: "Délivré",
  },
  clicks: {
    color: "info",
    text: "Click",
  },
  requests: {
    color: "info",
    text: "Requête",
  },
  opened: {
    color: "secondary",
    text: "Ouvert",
  },
  bounces: {
    color: "error",
    text: "Bounce",
  },
  hardBounces: {
    color: "error",
    text: "Hard Bounce",
  },
  softBounces: {
    color: "error",
    text: "Soft Bounce",
  },
  spam: {
    color: "error",
    text: "Spam",
  },
  error: {
    color: "error",
    text: "Erreur",
  },
  invalid: {
    color: "error",
    text: "Invalide",
  },
  deferred: {
    color: "error",
    text: "Déférré",
  },
  blocked: {
    color: "error",
    text: "Bloqué",
  },
  unsubscribed: {
    color: "error",
    text: "Désinscription",
  },
  loadedByProxy: {
    color: "secondary",
    text: "Chargé (apple)",
  },
};

const EmailsList = ({ ...props }) => {
  const { isLoading, identity } = useGetIdentity();
  return isLoading || !props?.email ? null : (
    <>
      <List
        perPage={10}
        {...props}
        actions={<></>}
        filter={{ email: props.email }}
        exporter={false}
        empty={false}
        sx={{ width: 1 }}
        sort={{ field: "created", order: "DESC" }}
      >
        <CustomDatagrid bulkActionButtons={false}>
          <DateField label="Date envoi" source="date" showTime />
          <TextField label="Destinataire" source="email" />
          <TextField label="Sujet" source="subject" />
          <FunctionField
            label="État"
            render={({ event }: any) => {
              return <ListChip label={emailEvents[event]?.text} color={emailEvents[event]?.color} />;
            }}
          />
          {/* <TextField label="Message" source="message" /> */}
        </CustomDatagrid>
      </List>
    </>
  );
};

export default EmailsList;
