import React, { useCallback, useState } from "react";
import { useRefresh, useNotify, useCreate, FormDataConsumer } from "react-admin";
import SmsTextInput from "../../components/inputs/SmsInput";
import { darken, lighten, Typography, useTheme } from "@mui/material";
import { Mail } from "lucide-react";
import CreateInDialog from "../../components/react-admin/CreateInDialog";
import { object, string } from "zod";
import FormInput from "../../components/form/FormInput";

const formSchema = object({
  expeditor: string().nullish(),
  content: string().min(1, "Merci de renseigner le contenu du sms"),
});

const SendSmsAction = ({ record, companyCredits }: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const theme = useTheme();
  //using key for the react-admin dialog component is a hack to close the dialog after submitting
  //without interfering with the isOpen property.
  const [key, setKey] = useState(0);

  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      values.phonenumbers =
        "contacts" in record ? record.contacts.map((contact: any) => contact.phonenumber) : [record.phonenumber];
      try {
        await create("sms", { data: values }, { returnPromise: true });
        notify("Sms envoyé avec succès", {
          type: "success",
          messageArgs: { smart_count: 1 },
        });
      } catch (error) {
        notify("Erreur lors de l'envoi du sms", {
          type: "error",
          messageArgs: { smart_count: 1 },
        });
      }
      setKey((key) => key + 1);
      refresh();
    },
    [create, notify, refresh]
  );

  return (
    <CreateInDialog
      formSchema={formSchema}
      title={
        "contacts" in record
          ? `Envoi groupé de SMS - liste de contact "${record.name}" (${record.contacts.length} contacts)`
          : `Envoi d'un SMS - ${record.firstname} ${record.lastname}`
      }
      label={
        <Typography variant="medium_sm" ml={1} color={"common.text"}>
          Envoyer SMS
        </Typography>
      }
      icon={<Mail size={18} color={theme.palette.common.text} />}
      resource="sms"
      key={key}
      confirmLabel="Envoyer"
      formProps={{ onSubmit: save }}
      fullWidthFields
      ButtonProps={{
        sx: {
          color: theme.palette.button.secondary.contained.content,
          backgroundColor: theme.palette.button.secondary.contained.background,
          "&:hover": {
            backgroundColor:
              theme.palette.mode === "light"
                ? darken(theme.palette.button.secondary.contained.background, 0.2)
                : lighten(theme.palette.button.secondary.contained.background, 0.3),
          },
          "& .MuiButton-startIcon": { mx: 0 },
        },
      }}
    >
      {companyCredits && companyCredits >= ("contacts" in record ? record.contacts.length : 1) ? (
        <>
          <Typography mb={2}>Il vous reste {companyCredits} sms disponibles</Typography>
          <FormDataConsumer>
            {({ formData, ...rest }) => (
              <FormInput
                checkboxLabel="Personnaliser l'expéditeur"
                name="customExpeditor"
                defaultValue={false}
                type="checkbox"
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.customExpeditor && (
                <FormInput
                  label="Expéditeur"
                  name="expeditor"
                  defaultValue={null}
                  helperText="11 caractères au maximum"
                />
              )
            }
          </FormDataConsumer>
          <SmsTextInput source="content" defaultValue={null} label="SMS à envoyer" direction="column" />
        </>
      ) : (
        <Typography sx={{ p: 4 }} color={"error"}>
          Vous n'avez pas assez de SMS disponibles, merci d'en racheter dans la boutique pour pouvoir continuer à
          utiliser vos services
        </Typography>
      )}
    </CreateInDialog>
  );
};

export default SendSmsAction;
