import React from "react";
import { CreateButton, DateField, Filter, FunctionField, TextField, TextInput, TopToolbar } from "react-admin";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import { List } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";
import { Typography } from "@mui/material";
import { ListActions } from "../../components/react-admin/ListActions";
import FormInput from "../../components/form/FormInput";

const ProductsList = ({ ...props }) => {
  return (
    <>
      <PageTitle
        text="Produits"
        createButton={<CreateButton label="Nouveau produit" resource="products" />}
        type="list"
        breadcrumbListLabel="Liste des produits"
      />
      <List
        perPage={10}
        {...props}
        filters={
          <Filter {...props}>
            <FormInput type="search" placeholder="Nom produit" source="name" alwaysOn />
          </Filter>
        }
        actions={<ListActions />}
        sort={{ field: "order", order: "ASC" }}
      >
        <CustomDatagrid export={false}>
          <TextField source="name" label="Nom" />
          <FunctionField
            label="Type"
            render={(record: any) => {
              return (
                <Typography variant="regular_sm">
                  {record.type} - {record.isRecurring ? "Abonnement" : "Achat ponctuel"}
                </Typography>
              );
            }}
          />
          <DateField label="Actualisé le" source="lastupdate" showTime />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default ProductsList;
