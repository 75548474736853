import BadWordsList from "./BadWordsList";
import BadWordsCreate from "./BadWordsCreate";
import BadWordsEdit from "./BadWordsEdit";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: BadWordsList,
  create: BadWordsCreate,
  edit: BadWordsEdit,
};
