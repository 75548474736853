import SubscriptionsList from './SubscriptionsList';
import SubscriptionsCreate from './SubscriptionsCreate';
import SubscriptionsEdit from './SubscriptionsEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: SubscriptionsList,
  create: SubscriptionsCreate,
  edit: SubscriptionsEdit
};
