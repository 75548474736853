import React from "react";
import {
  Edit,
  FormDataConsumer,
  ReferenceArrayInput,
  SimpleForm,
  ReferenceField,
  useGetIdentity,
  useNotify,
  ReferenceInput,
  FunctionField,
  DateField,
  TextField,
  useGetList,
  Datagrid,
} from "react-admin";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import EmailsList, { emailEvents } from "./EmailsList";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import ListChip from "../../components/fields/ListChip";
import { Box, Typography } from "@mui/material";

const EmailsEdit = ({ ...props }) => {
  return (
    <Edit {...props} hasShow={false}>
      <PageTitle
        text={(record: any) => "Détails email"}
        type="edit"
        resource="opportunities"
        breadcrumbListLabel="Liste Opportunités"
      />
      <SimpleForm toolbar={<></>}>
        <Box
          sx={{
            display: ["flex", "flex", "grid"],
            flexDirection: ["column"],
            gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"],
            gap: [0, "0 2rem"],
          }}
        >
          <FormInput label="Date envoi" source="date" type="dateTime" disabled />
          <FormInput label="Destinataire" source="email" disabled />
          <FormInput label="Sujet" source="subject" disabled />
          <FormInput label="Identifiant modèle" source="templateId" disabled />
        </Box>

        <Typography variant="bold_sm">Historique événements</Typography>
        <FunctionField
          sx={{ width: "100%" }}
          render={({ events }: any) => {
            return (
              <Datagrid
                resource="emails"
                data={events}
                total={events?.length}
                isPending={false}
                sort={{ field: "date", order: "ASC" }}
                bulkActionButtons={false}
                sx={{ "& .MuiTableHead-root": { display: "none" } }}
              >
                <DateField label="Date événement" source="date" showTime />
                <FunctionField
                  label="État"
                  render={({ event }: any) => {
                    return <ListChip label={emailEvents[event]?.text} color={emailEvents[event]?.color} />;
                  }}
                />
              </Datagrid>
            );
          }}
        />
        {/* <FormInput label="Message" source="message" disabled type="multiline" /> */}
      </SimpleForm>
    </Edit>
  );
};

export default EmailsEdit;
