import React, { useEffect, useRef, useState } from "react";
import { count } from "sms-length";
import { useRecordContext } from "react-admin";
import {
  Menu,
  Button,
  Box,
  Chip,
  MenuItem,
  Typography,
  useTheme,
  Tooltip,
  alpha,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import FormInput from "../form/FormInput";
import { ScanText, TextCursor, TextCursorInput, Verified } from "lucide-react";
import api from "../../services/apiWithAuth";

const getHelperFromSms = (Sms: string) => {
  const { length, messages, remaining } = count(Sms || "");
  let warning = "";
  if ((length + remaining) / messages <= 70) {
    warning =
      "Attention : votre texte contient au moins un caractère unicode, la longueur d'un SMS est donc réduite et la quantité de crédits utilisée peut être augmentée";
  }
  return {
    sizeInformations: `${length}/${length + remaining} = ${messages} SMS`,
    warning,
  };
};

const SmsTextInput = ({ source, label, validator, direction = "row", ...props }: any) => {
  const record = useRecordContext(props);
  const [helper, setHelper] = useState(getHelperFromSms(record ? record[source] : ""));
  const [foundBadWords, setFoundBadWords] = useState([] as any);
  const [newCursorPosition, setNewCursorPosition] = useState(0);
  const [selectedVariable, setSelectedVariable] = useState<null | string>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [contentChecked, setContentChecked] = useState(false);
  const inputRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const { setValue } = useFormContext();
  const smsInputValue = useWatch({ name: source });

  const variables = {
    TITLE: "Objet RDV",
    DATE: "Date début RDV",
    DATE_END: "Date fin RDV",
    HOUR: "Heure début RDV",
    HOUR_END: "Heure fin RDV",
    LOCATION: "Lieu RDV",
    USER_CIVILITY: "Civilité destinataire",
    USER_FIRSTNAME: "Nom destinataire",
    USER_NAME: "Prénom destinataire",
    USER_PHONE: "Téléphone destinataire",
  };

  useEffect(() => {
    if (selectedVariable) {
      setNewCursorPosition(inputRef?.current?.selectionStart + selectedVariable.length);
      const sms = smsInputValue || "";
      setValue(
        source,
        sms.substring(0, inputRef?.current?.selectionStart) +
          selectedVariable +
          sms.substring(inputRef?.current?.selectionStart, sms.length)
      );
      inputRef.current.focus();
      setSelectedVariable(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariable]);

  useEffect(() => {
    if (newCursorPosition) {
      inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
      setNewCursorPosition(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCursorPosition]);

  useEffect(() => {
    if (!inputRef.current && containerRef.current) {
      inputRef.current = containerRef.current.getElementsByTagName("textarea")[0];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef?.current]);

  useEffect(() => {
    //? if clause strange because with it when field is reset helper text is not updated, so maybe there was a reason to put it
    // if (inputRef?.current?.value) {
    setHelper(getHelperFromSms(inputRef?.current?.value));
    setContentChecked(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef?.current?.value]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: ["column", direction], gap: [0, 2], width: [1, "fit-content"], mb: 2 }}
      ref={containerRef}
    >
      <FormInput
        name={source}
        label={label}
        helperText={
          <Box>
            <Box sx={{ width: 1, display: "flex", justifyContent: "space-between" }}>
              <Typography variant="regular_sm">{helper?.sizeInformations || ""}</Typography>
              <VariablesMenu variables={variables} setSelectedVariable={setSelectedVariable} />
            </Box>
            {helper?.warning && (
              <Typography variant="regular_sm" color="warning.main">
                {helper.warning}
              </Typography>
            )}
            {foundBadWords?.length > 0 && (
              <Typography variant="regular_sm" color="error.main">
                {foundBadWords?.length > 1
                  ? `Les mots "${foundBadWords?.join(
                      '", "'
                    )}" trouvés dans votre texte sont interdits, merci de les enlever.`
                  : `Le mot "${foundBadWords[0]}" trouvé dans votre texte est interdit, merci de l'enlever.`}
              </Typography>
            )}
          </Box>
        }
        type="multiline"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Vérifiez que votre texte ne contienne pas de mots interdits">
                <IconButton
                  aria-label="reset value"
                  onClick={async () => {
                    setIsChecking(true);
                    const { data: badWordsList } = await api.post("/badWords/validate", {
                      content: inputRef?.current?.value,
                      type: "content",
                    });
                    setFoundBadWords(badWordsList?.map((badWord) => badWord.word));
                    if (badWordsList?.length === 0) {
                      setContentChecked(true);
                    }
                    setIsChecking(false);
                  }}
                  disabled={contentChecked}
                  sx={{ ml: -1, color: "primary.main" }}
                >
                  {isChecking ? (
                    <CircularProgress size={18} />
                  ) : !contentChecked ? (
                    <ScanText size={18} />
                  ) : (
                    <Verified size={18} />
                  )}
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        setIsInputFocused={setIsFocused}
      />
    </Box>
  );
};

const VariablesMenu = ({ variables, setSelectedVariable }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Tooltip title="Vous pouvez insérer des variables personnalisées en fonction du rendez-vous et du destinataire en cliquant sur ce bouton">
        <Button
          id="variable-button"
          aria-controls={open ? "variable-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          sx={{ py: 0, backgroundColor: alpha(theme.palette.secondary.main, 0.2) }}
        >
          <Typography variant="regular_sm">Ajouter variable</Typography>
        </Button>
      </Tooltip>
      <Menu
        id="variable-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "variable-button",
        }}
      >
        {Object.keys(variables).map((variable: any) => (
          <MenuItem
            key={variable}
            onClick={() => {
              setSelectedVariable(`[${variable}]`);
              handleClose();
            }}
          >
            {variables[variable]}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default SmsTextInput;
