import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import {
  Edit,
  Labeled,
  FunctionField,
  TextField,
  DateField,
  Toolbar,
  SaveButton,
  DeleteButton,
  useRecordContext,
  useGetIdentity,
  BooleanField,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";

const SmsToolBar = () => {
  const record = useRecordContext();
  return (
    <Toolbar>
      {/* <SaveButton label="Sauvegarder" /> */}
      {!record.sent && <DeleteButton label="Annuler" />}
    </Toolbar>
  );
};

const SmsEdit = ({ ...props }) => {
  const { data: identity } = useGetIdentity();
  return (
    <Edit {...props}>
      <PageTitle text={"Aperçu du SMS"} type="edit" resource="sms" breadcrumbListLabel="Journal d'envoi des sms" />
      <SimpleForm toolbar={<SmsToolBar />}>
        <Grid
          direction={"column"}
          sx={{ display: "grid", gridTemplateColumns: ["1fr", "1fr", "1fr 1fr"], gap: [0, "0 2rem"] }}
        >
          <Box
            sx={{
              gridColumn: identity?.status !== "Collaborateur" ? "span 2" : "auto",
              width: 1,
              "& .MuiFormControl-root": { width: 1 },
            }}
          >
            <FormInput label="Date d'envoi" source="send_date" disabled type="dateTime" />
          </Box>

          {identity?.status === "Collaborateur" && <FormInput label="Nom externe" source="external_name" disabled />}

          <FormInput label="Destinataire" source="phonenumber" disabled type="phone" />
          <FormInput label="Expéditeur" source="expeditor" disabled />
          <Box sx={{ display: "flex", flexDirection: ["column", "row"], width: 1, gridColumn: "span 2" }}>
            <FormInput label="Contenu du sms" source="content" disabled type="multiline" lineNumber={5} />
            <Box>
              <FormInput label="Côut en crédits du sms" source="length" disabled />
              <FormInput
                label="Type"
                source="type"
                type="select"
                values={[
                  { id: "reminder", label: "Rappel" },
                  { id: "confirmation", label: "Confirmation" },
                ]}
                disabled
              />
            </Box>
          </Box>
          <FormInput label="Utilisation de caractère(s) unicode" source="isUnicode" type="checkbox" disabled />
          <FormInput label="Envoyé" source="sent" type="checkbox" disabled />
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default SmsEdit;
