import React, { Children, useCallback } from "react";
import { CreateButton, DateField, Filter, FunctionField, Pagination, TextField, useGetIdentity } from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import { ICompany } from "./ICompany";
import { COMPANIES_SECTORS, COMPANIES_STATUSES, COUNTRIES, TYPES } from "../../services/common-utils/constants";
import CountryField from "../../components/fields/CountryField";
import PageTitle from "../../components/navigation/PageTitle";
import { query, where } from "firebase/firestore";
import FormInput from "../../components/form/FormInput";
import { ListActions } from "../../components/react-admin/ListActions";
import { Typography, useTheme } from "@mui/material";
import ListChip from "../../components/fields/ListChip";
import { MessageCircle } from "lucide-react";

const CompaniesPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const CompaniesList = ({ ...props }) => {
  //Only "Collaborateur" and "Revendeur" have access to this page
  const { isLoading, data: identity } = useGetIdentity();
  const theme = useTheme();

  return isLoading ? (
    <></>
  ) : (
    <>
      <PageTitle
        text="Clients"
        backButton={false}
        createButton={<CreateButton label="Nouveau Client" />}
        type="list"
        resource="companies"
        breadcrumbListLabel="Liste clients"
      />
      <List
        // perPage={10}
        filter={
          identity?.status === "Collaborateur"
            ? {}
            : {
                collectionQuery: (c: any) =>
                  query(c, where("id", "!=", identity?.company), where("parentCompany", "==", identity?.company)),
              }
        }
        sort={{ field: "company", order: "ASC" }}
        filters={
          <Filter {...props}>
            <FormInput name="multi_company" placeholder="Rechercher par nom" type="search" alwaysOn />
            {identity?.status === "Collaborateur" && (
              <FormInput
                placeholder="Statut"
                type="select"
                source="status"
                values={COMPANIES_STATUSES.map((s) => ({ id: s, label: s }))}
                filledSecondary
                label="Statut"
                hideLabel
              />
            )}
            {identity?.status === "Collaborateur" && (
              <FormInput
                placeholder="Prospect"
                type="select"
                source="prospect"
                values={[
                  { id: "true", label: "Prospect" },
                  { id: "false", label: "Client" },
                ]}
                defaultValue={false}
                label="Prospect"
                hideLabel
                filledSecondary
              />
            )}
            <FormInput
              label="Nature"
              hideLabel
              placeholder="Nature"
              type="select"
              source="type"
              values={TYPES.map((s) => ({ id: s, label: s }))}
              filledSecondary
            />
            <FormInput
              label="Activité"
              hideLabel
              placeholder="Activité"
              type="select"
              source="sector"
              values={COMPANIES_SECTORS.map((s) => ({ id: s, label: s }))}
              filledSecondary
            />
            <FormInput
              label="Pays"
              hideLabel
              placeholder="Pays"
              type="select"
              source="country"
              values={COUNTRIES}
              filledSecondary
            />
          </Filter>
        }
        pagination={<CompaniesPagination />}
        {...props}
        actions={<ListActions />}
        empty={false}
        exporter={false}
      >
        <CustomDatagrid bulkActionButtons={false}>
          <TextField source="company" label="Nom" />
          <TextField source="type" label="Nature" />
          <TextField source="sector" label="Activité" />
          <CountryField source="country" label="Pays" />
          {identity?.status === "Collaborateur" && (
            <FunctionField
              label="Statut"
              render={(record: ICompany) => {
                const statusColor =
                  record.status === "Client"
                    ? "secondary"
                    : record.status === "Revendeur"
                    ? "primary"
                    : record.status === "Indirect"
                    ? "info"
                    : "primary";
                return <ListChip label={record.status} color={statusColor} />;
              }}
            />
          )}
          <FunctionField
            label="Crédits"
            render={(record: any) => {
              const creditsColor = record.credits <= 0 ? "error" : record.credits <= 100 ? "warning" : "secondary";
              return (
                <ListChip
                  label={
                    <>
                      <MessageCircle size={18} />
                      {record.credits}
                    </>
                  }
                  color={creditsColor}
                />
              );
            }}
          />
          <DateField label="Modifié le" source="lastupdate" showTime={true} />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default CompaniesList;
