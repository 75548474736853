import { object, string, z, optional, array, boolean, number, any } from "zod";

const tarifSchema = object({
  billingPeriod: string().nullish(),
  price: number(),
  packageSize: number().nullish(),
  countries: array(string()),
  companyStatus: array(string()),
  companyTypes: array(string()),
  companyIds: array(string()),
  blackListedCompanyIds: array(string()),
  id: any(),
});

export const schema = object({
  name: string().min(1, "Nom du produit requis"),
  description: string().min(1, "Description produit requise"),
  taxRate: number(),
  type: string().min(1, "Type du produit requis"),
  isRecurring: boolean(),
  paymentModel: string(),
  tarifs: array(tarifSchema),
});

export type Tarif = z.infer<typeof tarifSchema>;
export type Product = z.infer<typeof schema>;
