import React, { useState, useEffect, useRef } from "react";
import { useInterval, useLocalStorage } from "usehooks-ts";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./checkout-form/CheckoutForm";
// import CheckoutDelivery from './checkout-delivery/checkout-delivery'
import CheckoutPayment from "./checkout-payment/CheckoutPayment";
import CheckoutResume from "./checkout-resume/CheckoutResume";
import CheckoutStepper from "./checkout-stepper/CheckoutStepper";
import { Box, useTheme } from "@mui/material";
import { useGetIdentity } from "react-admin";
import priceCalculator from "../../services/common-utils/priceCalculator";
import api from "../../services/apiWithAuth";
import CheckoutSuccess from "./checkout-success/CheckoutSuccess";
import { isEqual } from "lodash";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");
const CheckoutPage = () => {
  const theme = useTheme();
  const [cart, setCart] = useLocalStorage("cart", [] as any[]);
  const [checkoutStep, setCheckoutStep] = useLocalStorage("checkoutStep", 0);
  const [paymentFormSubmitting, setPaymentFormSubmitting] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
  const [paidWith, setPaidWith] = useState("");
  const [customerInfos, setCustomerInfos] = useState({});
  const [discount, setDiscount] = useLocalStorage("discount", ({} as any) || null);
  const [totals, setTotals] = useState({
    totalHt: 0,
    taxes: { total: 0 },
    totalTtc: 0,
    withoutDiscount: { totalHt: 0, taxes: 0, totalTtc: 0 },
    savings: { totalHt: 0, taxes: 0, totalTtc: 0 },
  } as any);
  const [checkoutCompleted, setCheckoutCompleted] = useState(false);
  const [checkoutFailed, setCheckoutFailed] = useState(false);
  const { isLoading, data: identity } = useGetIdentity();
  const callTimestamp = useRef(null as any);

  const handleStep = (direction: number) => {
    if ((checkoutStep < 2 && direction === 1) || (checkoutStep > 0 && direction === -1)) {
      // const newStep = checkoutStep + direction;
      // setCheckoutStep(newStep);
      setCheckoutStep((prevcheckoutStep: number) => prevcheckoutStep + direction);
      window.scrollTo({ top: 0 });
    }
  };

  const handleCheckoutCompleted = (paymentType: string) => {
    setPaidWith(paymentType);
    setCheckoutCompleted(true);
    setDiscount({});
    setCart([]);
    localStorage.removeItem("paymentIntentId");
    localStorage.removeItem("discount");
    localStorage.removeItem("clientSecret");
    localStorage.removeItem("prevPaymenIntentTotals");
    setCheckoutStep(2);
  };

  const handleCheckoutFailed = () => {
    setCheckoutFailed(true);
  };

  useEffect(() => {
    if (identity) {
      setCustomerInfos({
        firstName: identity?.billingDetails.firstName || null,
        lastName: identity?.billingDetails.lastName || null,
        email: identity?.billingDetails.email || null,
        contactPhone: identity?.proPhone || null,
        billingAddress: identity?.billingDetails.address || null,
        billingZipCode: identity?.billingDetails.zipCode || null,
        billingCity: identity?.billingDetails.city || null,
      });
    }
  }, [identity, setCustomerInfos]);

  useEffect(() => {
    //allow to avoid multiple payment intent creation
    const prevTimestamp = callTimestamp.current;
    const currentTimestamp = Date.now();
    callTimestamp.current = currentTimestamp;
    const isNotComponentRerender = !prevTimestamp || currentTimestamp - prevTimestamp > 600;

    const asyncCall = async () => {
      const paymentIntentId = localStorage.getItem("paymentIntentId") || "";
      const prevPaymenIntentTotals = JSON.parse(localStorage.getItem("prevPaymenIntentTotals") || "{}");
      if (
        isNotComponentRerender &&
        checkoutStep === 1 &&
        totals.totalTtc &&
        (!paymentIntentId || (paymentIntentId && !isEqual(prevPaymenIntentTotals, totals)))
      ) {
        const cartWithoutAdditionnalInfos = cart.map((item: any) => {
          const tarifs = item.product.tarifs.map((tarif: any) => {
            delete tarif.priceHT;
            delete tarif.periodLabel;
            delete tarif.choiceLabel;
            return tarif;
          });
          return {
            ...item,
            product: {
              ...item.product,
              tarifs,
            },
          };
        });
        const data = {
          cart: cartWithoutAdditionnalInfos,
          discountId: discount?.id || null,
          totals,
          paymentIntentId,
        };
        try {
          const res = await api.post("/utils/createOrUpdatePaymentIntent", data);
          if (res.data?.id) {
            localStorage.setItem("clientSecret", res.data.clientSecret);
            localStorage.setItem("paymentIntentId", res.data.id);
            localStorage.setItem("orderReference", res.data.orderReference);
            localStorage.setItem("prevPaymenIntentTotals", JSON.stringify(totals));
          }
        } catch (err) {
          console.log("error during payment intent creation or edition", err);
        }
      }
    };
    asyncCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals, checkoutStep]);

  useEffect(() => {
    setTotals(priceCalculator(cart, discount, identity?.companyCountry));
  }, [discount, cart, setTotals, identity?.companyCountry]);

  return isLoading ? null : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{ width: "100%", maxWidth: 1500, mt: 3, color: theme.palette.mode === "light" ? "black" : "white" }}
    >
      <Box sx={{ width: [1, 0.9] }}>
        {checkoutStep < 2 && <CheckoutStepper checkoutStep={checkoutStep} />}
        <Box
          display="flex"
          justifyContent="space-around"
          width="100%"
          gap="30px"
          sx={{ flexDirection: ["column", "row"] }}
        >
          <Box sx={{ width: [1, 0.7] }}>
            {checkoutStep === 0 && Object.keys(customerInfos).length > 0 && identity && (
              <CheckoutForm
                handleStep={handleStep}
                customerInfos={customerInfos}
                setCustomerInfos={setCustomerInfos}
                identity={identity}
              />
            )}
            {checkoutStep === 1 && (
              <Elements stripe={stripePromise}>
                <CheckoutPayment
                  totals={totals}
                  handleStep={handleStep}
                  handleCheckoutCompleted={handleCheckoutCompleted}
                  handleCheckoutFailed={handleCheckoutFailed}
                  cart={cart}
                  customerInfos={customerInfos}
                  discount={discount}
                  setPaymentFormSubmitting={setPaymentFormSubmitting}
                  setPaymentErrorMessage={setPaymentErrorMessage}
                />
              </Elements>
            )}
            {checkoutStep === 2 && <CheckoutSuccess paidWith={paidWith} setCheckoutStep={setCheckoutStep} />}
          </Box>
          {checkoutStep < 2 && (
            <Box sx={{ width: [1, "25rem"] }}>
              <CheckoutResume
                cart={cart}
                setCart={setCart}
                identity={identity}
                totals={totals}
                checkoutStep={checkoutStep}
                handleStep={handleStep}
                discount={discount}
                setDiscount={setDiscount}
                paymentFormSubmitting={paymentFormSubmitting}
                paymentErrorMessage={paymentErrorMessage}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CheckoutPage;
