import React from "react";
import { CreateButton, DateField, Filter, FunctionField, TextField, useRefresh } from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import { ListActions } from "../../components/react-admin/ListActions";
import PageTitle from "../../components/navigation/PageTitle";
import { BADWORDS_TYPES } from "../../services/common-utils/constants";
import CustomExportButton from "../../components/react-admin/CustomExportButton";
import { Box } from "@mui/material";
import NewImportButtons from "../../components/importButtons/NewImportButtons";
import api from "../../services/apiWithAuth";
import FormInput from "../../components/form/FormInput";

const getFields = () => {
  const fields: any = [
    {
      label: "Mot interdit",
      key: "word",
      alternateMatches: ["BadWord", "badWord", "badword", "mot interdit", "Mot", "mot"],
      fieldType: {
        type: "input",
      },
      example: "amazon",
      validations: [
        {
          rule: "required",
          errorMessage: "Mot requis",
          level: "error",
        },
      ],
    },
    {
      label: "Type",
      key: "type",
      alternateMatches: ["type"],
      fieldType: {
        type: "input",
      },
      example: "expeditor | content | both",
      validations: [
        {
          rule: "required",
          errorMessage: "Type requis",
          level: "error",
        },
      ],
    },
  ];
  return fields;
};

const BadWordsList = ({ ...props }) => {
  const refresh = useRefresh();

  const onSubmit = async (data: any) => {
    const {
      data: { badWordsCreated },
    } = await api.post("/badWords/import", {
      data,
    });
    refresh();
  };

  return (
    <>
      <PageTitle
        text="Mots interdits"
        createButton={<CreateButton label="Nouveau mot interdit" resource="badWords" />}
        type="list"
        breadcrumbListLabel="Liste des mots interdits"
      />
      <List
        perPage={10}
        {...props}
        actions={
          <ListActions>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
              <NewImportButtons
                {...props}
                fields={getFields()}
                onSubmit={onSubmit}
                rowHook={async (data, addError) => {
                  if (data?.type && !["expeditor", "content", "both"].includes(data.type)) {
                    addError("type", { message: data.type + " n'est pas un type valide", level: "error" });
                  }
                  return { ...data, word: data.word.trim().toLowerCase() };
                }}
              />
            </Box>
            <CustomExportButton
              fields={[
                { variable: "word", label: "Mot" },
                { variable: "type", label: "Type" },
              ]}
              fileName="badWords"
              maxResults={100}
            />
          </ListActions>
        }
        filters={
          <Filter {...props}>
            <FormInput name="query" placeholder="Rechercher" type="search" alwaysOn />
          </Filter>
        }
        exporter={false}
        empty={false}
        sort={{ field: "createdAt", order: "DESC" }}
      >
        <CustomDatagrid>
          <TextField label="Mot" source="word" sortable={false} />
          <FunctionField
            label="Type"
            render={(record: any) =>
              BADWORDS_TYPES.find((badWordType: any) => badWordType?.id === record?.type)?.label || ""
            }
          />
          <DateField label="Créé à" source="createdAt" showTime sortable={false} />
          <DateField label="Modifié à" source="updatedAt" showTime sortable={false} />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default BadWordsList;
