import axios from 'axios';
import { fbAuth } from "./firebase/firebase.services";

let url = process.env.REACT_APP_CLOUD_FUNCTION_API_URL;
if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
  url = 'http://127.0.0.1:5001/'+process.env.REACT_APP_FIREBASE_PROJECT_ID+'/europe-west1/digisoftApp/api'
}

export const apiUrl = url;

const api = axios.create({
  baseURL: url
})

api.interceptors.request.use(async config => {
  const user = fbAuth.currentUser
  if (user) {
    const token = await user.getIdToken()
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}, error => {
  return Promise.reject(error)
})

export default api;