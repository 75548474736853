import { Box, Typography } from "@mui/material";
import React from "react";
import { FunctionField, SelectInput, required, useGetOne, useRecordContext } from "react-admin";

const PaymentMethodElement = ({ paymentMethod }: any) => {
  const visa = require("../../assets/visa.png") as string;
  const mastercard = require("../../assets/mastercard.png") as string;
  const amex = require("../../assets/amex.png") as string;
  const sepa = require("../../assets/sepa.png") as string;
  const identificator =
    paymentMethod.type === "card"
      ? "**** **** **** " + paymentMethod.card.last4
      : `${paymentMethod.sepa_debit.country}**${paymentMethod.sepa_debit.bank_code}${paymentMethod.sepa_debit.branch_code}*********${paymentMethod.sepa_debit.last4}`;
  const paymentMethodToImage: any = {
    visa: visa,
    mastercard: mastercard,
    amex: amex,
    sepa: sepa,
  };
  const type = paymentMethod.type === "card" ? paymentMethod.card.brand : "sepa";
  return ["visa", "amex", "sepa", "mastercard"].includes(type) ? (
    <Box display="flex" flexDirection="row" alignItems="center" sx={{ m: 0.5, ml: 0 }}>
      <img src={paymentMethodToImage[type]} alt={type} width="40" height="25" />
      <Typography sx={{ ml: 2 }}>{identificator}</Typography>
    </Box>
  ) : (
    <div />
  );
};

const PaymentMethodInput = ({ ...props }: any) => {
  return (
    <SelectInput
      {...props}
      defaultValue={null}
      validate={required()}
      label="Moyen de paiement"
      optionText={(record) => {
        return <PaymentMethodElement paymentMethod={record}/>;
      }}
    />
  );
};

export default PaymentMethodInput;
