import React from "react";
import { CreateButton, TextField } from "react-admin";
import { List } from "@react-admin/ra-rbac";
import { CustomDatagrid } from "../../components/react-admin/CustomDatagrid";
import PageTitle from "../../components/navigation/PageTitle";
import { ListActions } from "../../components/react-admin/ListActions";

const DownloadsList = ({ ...props }) => {
  return (
    <>
      <PageTitle
        text="Connecteurs"
        createButton={<CreateButton label="Nouveau connecteur" resource="downloads" />}
        type="list"
        breadcrumbListLabel="Liste des connecteurs"
      />
      <List perPage={10} {...props} actions={<ListActions />} exporter={false} empty={false}>
        <CustomDatagrid>
          <TextField label="Nom du connecteur" source="name" />
          <TextField label="Version" source="version" />
        </CustomDatagrid>
      </List>
    </>
  );
};

export default DownloadsList;
