import React from "react";
import { IbanElement } from "@stripe/react-stripe-js";
import { Box } from "@mui/material";
function SepaElement() {
  const IBAN_STYLE = {
    base: {
      color: "#707070",
      fontSize: "18px",
      "::placeholder": {
        color: "#707070",
      },
      ":-webkit-autofill": {
        color: "#707070",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
      ":-webkit-autofill": {
        color: "#fa755a",
      },
    },
  };
const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ["SEPA"],
    style: IBAN_STYLE,
  };
  return (
    <Box sx={{border: "solid rgba(0,0,0,0.3) 1px", p: 1, borderRadius: 2}}>
      <IbanElement options={IBAN_ELEMENT_OPTIONS} />
    </Box>
  );
}
export default SepaElement;