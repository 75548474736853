import LicensesList from './LicensesList';
// import LicensesCreate from './LicensesCreate';
import LicensesEdit from './LicensesEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: LicensesList,
  // create: LicensesCreate,
  edit: LicensesEdit
};
