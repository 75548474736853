export const COUNTRIES = ["France", "Belgique", "Suisse", "Canada", "Guadeloupe", "Saint-Barthelemy"];
export const COUNTRIES_WITHOUT_VAT = ["Suisse", "Canada", "Guadeloupe", "Saint-Barthelemy"]; //out of EU
export const TYPES = ["Secteur public", "Association", "Entreprise privée"];
export const TITLES = ["Monsieur", "Madame"];
export const SERVICES = [
  "Administratif",
  "Achat",
  "Comptabilité",
  "Informatique",
  "Juridique",
  "R&D",
  "Ressources humaines",
  "Logistique",
  "Production",
  "Marketing",
  "Ventes",
  "Autres",
];
export const CRM_ROLES = ["Super Admin", "Direction", "Commercial", "Technique"];
export const STATUSES = ["Actif", "Inactif"];
export const COMPANIES_STATUSES = ["Client", "Revendeur", "Indirect"];
export const COMPANIES_SOURCES = [
  "sms-meeting.com",
  "Sms-outlook.com",
  "Web Marketing",
  "Réseaux B to B",
  "Digisoft.pro",
  "SEO",
  "Google Ads",
  "Autres",
];
export const COMPANIES_ACCOUNT_STATUSES = ["Actif", "Archivé", "Bloqué"];
export const COMPANIES_SECTORS = [
  "Action Sociale",
  "Administration publique",
  "Automobile",
  "Banque / Assurance",
  "BTP / Construction",
  "Commerce / Distribution",
  "Comptabilité",
  "Édition / Communication",
  "Études et conseils",
  "Formation continue",
  "Immobilier",
  "Industrie pharmaceutique",
  "Informatique / Télécoms",
  "Juridique / Droit",
  "Santé",
  "Sports",
  "Transports / Logistique",
];
export const USERS_ROLES = ["Administrateur", "Utilisateur sans accès"];
export const PRODUCTS_TYPES = ["API", "Licence", "Crédits SMS"];
export const PRODUCTS_PAYMENT_MODELS = ["Forfait", "Standard"];
export const PRODUCTS_TAXES = [0, 20];
export const PRODUCTS_BILLING_PERIOD = ["Quotidienne", "Mensuelle", "Annuelle"];
export const DELAYS = [
  { name: "15 minutes", id: 15 },
  { name: "30 minutes", id: 30 },
  { name: "1 heure", id: 60 },
  { name: "2 heures", id: 60 * 2 },
  { name: "4 heures", id: 60 * 4 },
  { name: "6 heures", id: 60 * 6 },
  { name: "8 heures", id: 60 * 8 },
  { name: "10 heures", id: 60 * 10 },
  { name: "1 jour", id: 1440 },
  { name: "2 jours", id: 1440 * 2 },
  { name: "8 jours", id: 1440 * 8 },
  { name: "4 heures après", id: -60 * 4 },
  { name: "1 jour après", id: -1440 },
];
export const DISCOUNT_APPLICATION_TYPES = ["Automatique", "Code promo"];
export const DISCOUNT_TYPES = ["Pourcentage", "Montant", "Produit offert", "Fidélité"];
export const DISCOUNT_SCOPES = ["Panier", "Produits spécifiques"];
export const OPPORTUNITIES_STATUSES = [
  "Gagné",
  "Perdu",
  "Lien parrainage envoyé",
  "Premier contact",
  "Démo programmée",
  "Essai gratuit",
  "Attente engagement",
];
export const MANUAL_PAYMENT_MODES = ["Virement", "Mandat administratif"];
export const CONTACT_VISIBILITIES = ["Tous les utilisateurs", "Cloisonné à un groupe d'utilisateurs", "Privé"];
export const RESOURCES_LIST = [
  "admin",
  "collaborators",
  "companies",
  "users",
  "orders",
  "products",
  "discounts",
  "attributes",
  "subscriptions",
  "licenses",
  "templates",
  "contacts",
  "contactLists",
  "sms",
  "providers",
  "paymentMethods",
  "emails",
  "groups",
  "downloads",
  "opportunities",
];

export const NOTIFICATION_TYPES = [
  {
    value: "welcome",
    label: "Bienvenu dans votre espace",
    recipient: "customer",
    methods: ["dashboard", "email"],
    emailTemplateId: 24,
  },
  {
    value: "noMoreCredits",
    label: "Plus aucun crédit restant",
    recipient: "customer",
    methods: ["dashboard", "email"],
    emailTemplateId: 6,
  },
  {
    value: "creditsTreshold",
    label: "Seuil de crédits restants",
    recipient: "customer",
    methods: ["dashboard", "email"],
    emailTemplateId: 27,
  },
  {
    value: "newOpportunity",
    label: "Nouvelle opportunité",
    recipient: "admin",
    methods: ["dashboard", "email"],
  },
  {
    value: "opportunityUpdate",
    label: "Mise à jour opportunité",
    recipient: "admin",
    methods: ["dashboard", "email"],
  },
  {
    value: "newOrder",
    label: "Nouvelle commande",
    recipient: "admin",
    methods: ["dashboard", "email"],
  },
  {
    value: "orderCompleted",
    label: "Commande finalisée",
    recipient: "admin",
    methods: ["dashboard", "email"],
  },
  {
    value: "paymentError",
    label: "Erreur de paiement",
    recipient: "admin",
    methods: ["dashboard", "email"],
  },
  {
    value: "subscriptionCancelled",
    label: "Abonnement annulé",
    recipient: "admin",
    methods: ["dashboard", "email"],
  },
  {
    value: "paymentErrorCustomer",
    label: "Erreur de paiement (Client)",
    recipient: "customer",
    methods: ["dashboard", "email"],
    //only for subscription for the while //todo : do it for orders too
    emailTemplateId: 8,
  },
  {
    value: "dispute",
    label: "Litige sur paiement",
    recipient: "admin",
    methods: ["dashboard", "email"],
  },
  {
    value: "disputeCustomer",
    label: "Litige sur paiement (client)",
    recipient: "customer",
    methods: ["dashboard", "email"],
  },
  {
    value: "7daysRemaining",
    label: "7 jours restants période d'essai",
    recipient: "customer",
    methods: ["dashboard", "email"],
    emailTemplateId: 9,
  },
  {
    value: "1dayRemaining",
    label: "1 jour restant période d'essai",
    recipient: "customer",
    methods: ["dashboard", "email"],
    emailTemplateId: 10,
  },
  {
    value: "endOfFreeTry",
    label: "Version d'essai expirée",
    recipient: "customer",
    methods: ["dashboard", "email"],
    emailTemplateId: 21,
  },
  {
    value: "affiliationGiftReferrer",
    label: "Cadeau parrain",
    recipient: "customer",
    methods: ["dashboard"],
  },
  {
    value: "affiliationGiftAffiliated",
    label: "Cadeau filleul",
    recipient: "customer",
    methods: ["dashboard"],
  },
];
export const BADWORDS_TYPES = [
  { id: "expeditor", label: "Expéditeur" },
  { id: "content", label: "Contenu" },
  { id: "both", label: "Expéditeur + Contenu" },
];
