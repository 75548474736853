import React, { useContext, useState, useEffect } from "react";
import {
  Edit,
  useNotify,
  BooleanInput,
  ReferenceInput,
  useGetIdentity,
  FormDataConsumer,
  Toolbar,
  DeleteWithConfirmButton,
  SaveButton,
  useRecordContext,
  useGetList,
} from "react-admin";
import { SimpleForm } from "@react-admin/ra-rbac";
import { HistoryContext } from "../../services/historyProvider";
import useCustomListRedirect from "../../services/hooks/useCustomRedirect";
import PageTitle from "../../components/navigation/PageTitle";
import FormInput from "../../components/form/FormInput";
import { object, string, literal, boolean } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AutocompleteInput } from "../../components/form/AutocompleteInput";
import { query, where } from "firebase/firestore";
import { Typography } from "@mui/material";

const ToolBar = ({ ...props }) => {
  const record = useRecordContext();
  return (
    <Toolbar sx={{ gap: 2 }}>
      <SaveButton label="Sauvegarder" />
      <DeleteWithConfirmButton
        confirmTitle={`Suppression du contact ${record.firstname} ${record.lastname}`}
        confirmContent="Êtes-vous sur de vouloir supprimer ce contact ?"
        confirmColor="warning"
      />
    </Toolbar>
  );
};

const LicenseInput = () => {
  return (
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        return formData.public ? null : (
          <ReferenceInput
            source="userId"
            reference="users"
            filter={{
              collectionQuery: (c: any) =>
                query(c, where("company", "==", formData.group_id), where("licenseId", "!=", null)),
            }}
            sort={{ field: "firstName", order: "ASC" }}
            perPage={1000}
          >
            <AutocompleteInput
              placeholder="Utilisateur"
              optionText={(user: any) => `${user.firstName} ${user.lastName}`}
              filterToQuery={(search: any) => ({ multi_firstName_lastName: search })}
              helperText={
                <Typography fontSize="0.8rem">
                  Seule la licence reliée à l'utilisateur sélectionné pour accéder à ce contact
                </Typography>
              }
            />
          </ReferenceInput>
        );
      }}
    </FormDataConsumer>
  );
};

const formSchema = object({
  civility: string().nullish(),
  firstname: string().min(1, "Prénom requis"),
  lastname: string().min(1, "Nom de famille requis"),
  email: string().email("Adresse mail invalide").or(literal("")).nullish(),
  phonenumber: string().min(4, "Téléphone requis"),
  public: boolean().nullish(),
  userId: string().nullish(),
});

const ContactsEdit = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useCustomListRedirect();
  const { isLoading, data: identity } = useGetIdentity();
  const history: string[] = useContext(HistoryContext);
  const [fromCompany, setFromCompany] = useState("");
  // const [crmGroupId, setCrmGroupId] = useState("");
  // const dataProvider = useDataProvider();
  // const [update] = useUpdate();

  // Transform override
  const onTransform = async (data: any) => {
    delete data.customExpeditor;
    if (["Client", "Indirect"].includes(identity?.status)) {
      data.group_id = identity?.company;
    }
    // if (data.crmGroupId) {
    //   setCrmGroupId(data.crmGroupId);
    // }
    // delete data.crmGroupid;
    return data;
  };

  // Success override
  const onSuccess = async (data: any) => {
    // if (crmGroupId) {
    //   try {
    //     const groupRes = await dataProvider.getOne("groups", { id: crmGroupId });
    //     update("groups", {
    //       id: crmGroupId,
    //       data: { contacts: (groupRes.data.contacts || []).concat(data.id) },
    //       previousData: groupRes.data,
    //     });
    //   } catch (error) {}
    // }
    notify(`Contact modifié avec succès`, { type: "success" });
    redirect("contactsOrContactLists");
  };

  useEffect(() => {
    //route from routerContext can be not already updated
    if (history.length && !fromCompany && history[0] === window.location.pathname) {
      const match = history[1].match(/(?:companies\/)(.*)/);
      setFromCompany(match ? match[1] : "none");
    }
  }, [history, fromCompany]);

  return isLoading || !fromCompany ? (
    <></>
  ) : (
    <>
      <Edit {...props} transform={onTransform} mutationMode="optimistic" mutationOptions={{ onSuccess }}>
        <PageTitle
          text={(record: any) => `${record.firstname} ${record.lastname}`}
          type="edit"
          resource="contacts"
          breadcrumbListLabel="Liste des contacts"
          breadcrumbListUrl="/contactsOrContactLists"
        />
        <SimpleForm
          defaultValues={() => (fromCompany !== "none" ? { group_id: fromCompany } : {})}
          resolver={zodResolver(formSchema)}
          toolbar={<ToolBar identity={identity} />}
        >
          {["Collaborateur", "Revendeur"].includes(identity?.status) && (
            <ReferenceInput
              required
              source="group_id"
              reference="companies"
              filter={identity?.status === "Revendeur" ? { parentCompany: identity?.company } : {}}
              sort={{ field: "company", order: "ASC" }}
              perPage={100}
            >
              <AutocompleteInput
                label="Organisation"
                optionText="company"
                filterToQuery={(search: any) => ({ company: search })}
              />
            </ReferenceInput>
          )}
          <FormInput
            label="Civilité"
            source="civility"
            type="select"
            defaultValue={null}
            values={["M", "Mme"].map((s) => ({ id: s, label: s }))}
            select
            placeholder="choisissez..."
          />
          <FormInput required source="firstname" label="Prénom" />
          <FormInput required source="lastname" label="Nom" />
          <FormInput required source="phonenumber" label="Numéro de téléphone" />
          <FormInput source="email" label="Adresse email" defaultValue={null} />
          <BooleanInput defaultValue={true} source="public" label="Visible par tous les utilisateurs" />
          <LicenseInput />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default ContactsEdit;
