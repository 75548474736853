import React, { useEffect, useRef, useState } from "react";
import { useRecordContext } from "react-admin";
import {
  Menu,
  Button,
  Box,
  Chip,
  MenuItem,
  Typography,
  useTheme,
  Tooltip,
  alpha,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";
import FormInput from "../form/FormInput";
import { ScanText, Verified } from "lucide-react";
import api from "../../services/apiWithAuth";

const ExpeditorInput = ({ source, label, ...props }: any) => {
  const [foundBadWords, setFoundBadWords] = useState([] as any);
  const [contentChecked, setContentChecked] = useState(false);
  const inputValue = useWatch({ name: source });
  const [isChecking, setIsChecking] = useState(false);

  useEffect(() => {
    setContentChecked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <FormInput
      name={source}
      label={label}
      tooltip="L'expéditeur doit comporter un minimum de 3 caractères pour être personnalisé et ne doit pas commencer par plus de 3 chiffres consécutifs avant la première lettre. L'affichage de l'expéditeur dépend du type de téléphone : les espaces peuvent être supprimés, les accents et caractères spéciaux ne sont jamais pris en compte.France métropolitaine :
L'opérateur NRJ Mobile ne prend pas en compte les expéditeurs personnalisés.
L'opérateur Free Mobile ne prend pas en compte les personnalisations d'expéditeurs pour les SMS Marketing ne comprenant pas la mention STOP au 36xxx.
Dans ces deux cas, ils seront automatiquement remplacés par un numéro court et il est préférable de préciser le nom de votre boutique dans le corps du message.International :
Certains pays n'acceptent pas la personnalisation de l'expéditeur, nous vous invitons à nous contacter pour en savoir plus."
      {...props}
      helperText={
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {props?.helperText && (
            <Typography variant="regular_xs" color="common.text">
              {props.helperText}
            </Typography>
          )}
          {/\s/.test(inputValue) && (
            <Typography variant="regular_sm" color="error.main">
              Les espaces sont interdits
            </Typography>
          )}
          {foundBadWords?.length > 0 && (
            <Typography variant="regular_sm" color="error.main">
              {foundBadWords?.length > 1
                ? `Les mots "${foundBadWords?.join(
                    '", "'
                  )}" trouvés dans l'expéditeur sont interdits, merci de les enlever.`
                : `Le mot "${foundBadWords[0]}" trouvé dans l'expéditeur est interdit, merci de l'enlever.`}
            </Typography>
          )}
        </Box>
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Vérifiez que votre texte ne contienne pas de mots interdits">
              <IconButton
                aria-label="reset value"
                onClick={async () => {
                  setIsChecking(true);
                  const { data: badWordsList } = await api.post("/badWords/validate", {
                    content: inputValue,
                    type: "expeditor",
                  });
                  setFoundBadWords(badWordsList?.map((badWord) => badWord.word));
                  if (badWordsList?.length === 0) {
                    setContentChecked(true);
                  }
                  setIsChecking(false);
                }}
                disabled={contentChecked}
                sx={{ ml: -1, color: "primary.main" }}
              >
                {isChecking ? (
                  <CircularProgress size={18} />
                ) : !contentChecked ? (
                  <ScanText size={18} />
                ) : (
                  <Verified size={18} />
                )}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ExpeditorInput;
